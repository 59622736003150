<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link
          class="text-decoration--none"
          :to="{ name: 'newhome.resources' }"
        >
          Resources
        </router-link>
      </v-toolbar-title>
    </v-app-bar>

    <div class="ma-4">
      <div class="display-2 font-weight-bold my-6 text-capitalize">
        {{ displayName }}
      </div>

      <div class="my-6">
        <v-row>
          <v-col cols="12" md="6">
            <v-form ref="form" class="resource-form">
              <v-row>
                <v-col cols="12">
                  <label class="text-field-label">Title</label>
                  <v-text-field
                    class="mt-2"
                    v-model="form.title"
                    :error-messages="form.$getError('title')"
                    :hide-details="!form.$hasError('title')"
                    :loading="loading"
                    required
                    flat
                    solo
                  />
                </v-col>

                <v-col cols="12">
                  <label class="text-field-label">Safety Tips</label>
                  <v-textarea
                    class="mt-2"
                    v-model="form.safety_tips"
                    :error-messages="form.$getError('safety_tips')"
                    :hide-details="!form.$hasError('safety_tips')"
                    :loading="loading"
                    maxlength="255"
                    auto-grow
                    required
                    counter
                    flat
                    solo
                  />
                </v-col>

                <v-col cols="12">
                  <label class="text-field-label">Instructions</label>
                  <v-textarea
                    class="mt-2"
                    v-model="form.instructions"
                    :error-messages="form.$getError('instructions')"
                    :hide-details="!form.$hasError('instructions')"
                    :loading="loading"
                    maxlength="255"
                    auto-grow
                    required
                    counter
                    solo
                    flat
                  />
                </v-col>

                <!-- <v-col cols="12">
                  <label class="text-field-label">Milestone</label>
                  <v-select
                    class="mt-2"
                    v-model="form.milestone_id"
                    :error-messages="form.$getError('milestone_id')"
                    :hide-details="!form.$hasError('milestone_id')"
                    :items="milestones"
                    :loading="loading"
                    label="Milestone"
                    item-text="name"
                    item-value="id"
                    clearable
                    flat
                    solo
                  />
                </v-col> -->

                <v-col cols="12">
                  <label class="text-field-label">Attachments</label>
                  <v-file-input
                    class="mt-2"
                    v-model="attachments"
                    :error-messages="form.$getError('attachments')"
                    :prepend-inner-icon="icons.paperClip"
                    :loading="form.$busy"
                    :clearable="false"
                    placeholder="Select file attachments"
                    prepend-icon=""
                    @change="loadAttachments"
                    hide-details
                    multiple
                    flat
                    solo
                  />
                </v-col>

                <v-col>
                  <div
                    class="v-text-field__details pl-3 mt-2"
                    v-if="form.$getError('attachments')"
                  >
                    <div
                      class="v-messages theme--light error--text"
                      role="alert"
                    >
                      <div class="v-messages__wrapper">
                        <div class="v-messages__message">
                          {{ form.$getError('attachments')[0] }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    v-for="(attachment, rIndex) in form.attachments"
                    :key="rIndex"
                  >
                    <div
                      class="v-text-field__details pl-3 mt-2"
                      v-if="form.$getError('attachments.' + rIndex)"
                    >
                      <div
                        class="v-messages theme--light error--text"
                        role="alert"
                      >
                        <div class="v-messages__wrapper">
                          <div class="v-messages__message">
                            {{ form.$getError('attachments.' + rIndex)[0] }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <template v-for="(attachment, rIndex) in form.attachments">
                      <a :href="attachment.url" :key="rIndex" download>
                        <v-chip
                          class="ma-2"
                          color="primary"
                          @click:close="onRemoveAttachment(rIndex)"
                          close
                        >
                          {{ attachment.file_name || attachment.name }}
                        </v-chip>
                      </a>
                    </template>
                  </div>
                </v-col>
              </v-row>

              <v-divider class="mt-6" />

              <!-- Hidden temporarily -->
              <div v-if="false">
                <div>
                  <div
                    class="subtitle font-weight-bold my-6 text-capitalize"
                    @click="showOnboardingQuestions = !showOnboardingQuestions"
                  >
                    <a>
                      {{ showOnboardingQuestions ? 'Hide' : 'Show' }} Related
                      Onboarding Questions
                    </a>
                  </div>
                  <div v-if="showOnboardingQuestions">
                    <div
                      v-for="onboardingQuestion in onboardingQuestions"
                      :key="onboardingQuestion.id"
                    >
                      <div class="subtitle-1">
                        {{ onboardingQuestion.body }}
                      </div>
                      <div class="d-flex">
                        <v-checkbox
                          v-for="choice in onboardingQuestion.choices"
                          :key="choice.id"
                          :label="choice.name"
                          :value="choice.id"
                          v-model="selectedChoices"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    class="subtitle font-weight-bold my-6 text-capitalize"
                    @click="showFormQuestions = !showFormQuestions"
                    v-infinite-scroll="loadMore"
                    infinite-scroll-distance="100"
                    :infinite-scroll-disabled="loading"
                  >
                    <a>
                      {{ showFormQuestions ? 'Hide' : 'Show' }} Related Form
                      Questions
                    </a>
                  </div>

                  <div v-if="showFormQuestions">
                    <v-select
                      hide-details
                      flat
                      solo
                      dense
                      label="Milestone"
                      item-text="name"
                      item-value="id"
                      clearable
                      v-model="filter.milestone"
                      :items="milestones"
                      :loading="loading"
                      style="max-width: 400px;"
                      @change="filterFormQuestion"
                      class="mb-2"
                    />

                    <v-spacer />

                    <div
                      v-for="formQuestion in formQuestions"
                      :key="formQuestion.id"
                    >
                      <div class="subtitle-1">{{ formQuestion.body }}</div>
                      <div class="caption">
                        ({{ formQuestion.milestone.name }})
                      </div>
                      <div class="d-flex">
                        <v-checkbox
                          v-for="choice in formQuestion.choices"
                          v-model="selectedChoices"
                          :label="choice.name"
                          :value="choice.id"
                          :key="choice.id"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex mt-8">
                <v-btn
                  v-if="form.id"
                  color="error"
                  class="mr-4 px-6"
                  height="40px"
                  :loading="form.$busy"
                  @click="deleteDialog = true"
                  >Delete</v-btn
                >

                <v-spacer></v-spacer>

                <v-btn
                  color="primary"
                  class="px-6"
                  height="40px"
                  @click="resource ? updateResourceDetails() : addResource()"
                  :loading="form.$busy"
                  >Save</v-btn
                >
              </div>
            </v-form>
          </v-col>
        </v-row>

        <v-dialog v-model="deleteDialog" max-width="290">
          <v-card>
            <v-card-title class="headline">Confirm Delete</v-card-title>

            <v-card-text>Are you sure you want to delete this?</v-card-text>

            <v-card-actions>
              <v-btn text @click="deleteDialog = false" :loading="form.$busy">
                Cancel
              </v-btn>
              <v-spacer />
              <v-btn
                color="red"
                :loading="form.$busy"
                @click="removeResource"
                text
              >
                Yes, Proceed
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>

    <v-overlay absolute :value="loading" opacity="0.2">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import {
  mdiAlertCircleOutline,
  mdiCheck,
  mdiChevronLeft,
  mdiMinusCircle,
  mdiPaperclip
} from '@mdi/js'
import { mapActions, mapMutations, mapState } from 'vuex'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'

import Form from '@/utils/form'

export default {
  name: 'ResourceDetails',

  components: {
    AppBarNavIcon
  },

  directives: {
    infiniteScroll
  },

  data() {
    return {
      loading: false,
      deleteDialog: false,
      form: new Form({
        id: '',
        title: '',
        safety_tips: '',
        instructions: '',
        project_type_id: 3,
        attachments: [],
        related_choices: []
      }),
      attachments: [],
      selectedChoices: [],
      icons: {
        arrowLeft: mdiChevronLeft,
        check: mdiCheck,
        alert: mdiAlertCircleOutline,
        delete: mdiMinusCircle,
        paperClip: mdiPaperclip
      },
      showModal: false,
      showOnboardingQuestions: false,
      showFormQuestions: false,
      filter: {
        milestone: ''
      }
    }
  },

  computed: {
    ...mapState({
      resource: state => state.resource.resourceDetails,
      authUser: state => state.auth.user,
      milestones: state => state.milestone.list,
      onboardingQuestions: state => state.resource.onboardingQuestions,
      formQuestions: state => state.resource.formQuestions,
      formQuestionsMeta: state => state.resource.formQuestionsMeta
    }),
    displayName() {
      return this.form.title !== '' ? this.form.title : 'New Resource'
    }
  },

  methods: {
    ...mapActions({
      getOnboardingQuestion: 'resource/getOnboardingQuestions',
      getResourceDetails: 'resource/getResourceDetails',
      createResource: 'resource/createResource',
      updateResource: 'resource/updateResource',
      deleteResource: 'resource/deleteResource'
    }),

    ...mapMutations({
      clearResourceDetails: 'resource/clearResourceDetails'
    }),

    loadAttachments() {
      this.attachments.forEach(attachment =>
        this.form.attachments.push(attachment)
      )
      this.attachments = []
    },

    onRemoveAttachment(index) {
      this.form.attachments.splice(index, 1)

      this.form.$clearError('attachments.' + index)
    },

    feedSelectedChoices() {
      this.form.related_choices.forEach(choice =>
        this.selectedChoices.push(choice.id)
      )
    },

    async getResource() {
      this.loading = true

      await this.getResourceDetails(this.$route.params.id)
      this.form = new Form(this.resource)
      this.feedSelectedChoices()

      this.loading = false
    },

    async updateResourceDetails() {
      this.form.$busy = true
      this.form.$clearErrors()

      this.updateResource(this.getFormData())
        .then(() => {
          this.form.$busy = false
          this.showSnackbar('Resource details successfully updated!', 'success')
          this.$router.replace({ name: 'newhome.resources' })
        })
        .catch(err => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },

    async addResource() {
      this.form.$busy = true
      this.form.$clearErrors()
      this.createResource(this.getFormData())
        .then(() => {
          this.form.$busy = false
          this.showSnackbar('Resource successfully added!', 'success')
          this.$router.replace({ name: 'newhome.resources' })
        })
        .catch(err => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },

    removeResource() {
      this.form.$busy = true
      this.deleteResource(this.form.id)
        .then(() => {
          this.form.$busy = false
          this.deleteDialog = false
          this.showSnackbar('Resource deleted successfully!')
          this.$router.replace({ name: 'newhome.resources' })
        })
        .catch(err => {
          this.form.$busy = false
          if (err.response.status === 404) {
            this.deleteDialog = false
            this.showSnackbar(
              'The resource you are trying to delete is no longer existing.',
              'error'
            )
          }
        })
    },

    getFormData() {
      let data = new FormData()

      for (let key in this.form.$data()) {
        data.append(key, this.form[key])
      }

      this.selectedChoices.forEach(i => data.append('related_choices[]', i))

      for (let i = 0; i < this.form.attachments.length; i++) {
        let attachment = this.form.attachments[i]

        if (attachment instanceof File) {
          data.append('attachments[]', attachment)
          continue
        }

        data.append('attachments[]', JSON.stringify(attachment))
      }

      return data
    },

    showSnackbar(message, color) {
      this.$store.dispatch('showSnackbar', {
        message,
        color
      })
    },

    filterFormQuestion() {
      this.fetchFormQuestions() // find a way to get all questions
    },

    loadMore() {
      if (
        this.formQuestionsMeta.current_page < this.formQuestionsMeta.last_page
      ) {
        this.fetchFormQuestions(this.formQuestionsMeta.current_page + 1)
      }
    },

    async fetchFormQuestions(page = 1) {
      if (this.loading) return

      let params = {
        page
      }

      if (this.filter.milestone) {
        params.milestone = this.filter.milestone
      } else {
        params.choices = this.selectedChoices
      }

      this.loading = true
      this.loading = false
    }
  },

  async created() {
    if (this.$router.currentRoute.name === 'newhome.resources.details') {
      await this.getResource()
    }
  },

  destroyed() {
    this.clearResourceDetails()
  },

  watch: {
    $route() {
      this.getResource()
    }
  }
}
</script>
